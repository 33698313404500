import { useTheme, type SvgIconProps } from '@mui/material'

type Props = {
  color?: string
  width?: number | string
  height?: number | string
}

import { ReactElement } from 'react'

function AgencyIcon
  ({
    color,
    width = '86',
    height = '85',
    ...props
  }: Readonly<Props>): ReactElement<SvgIconProps> {
  // if color is undefined, get the primary main from useTheme
  const theme = useTheme()
  if (!color) {
    color = theme.palette.primary.main
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1141_2959)">
        <path
          d="M47.9688 0.977539L32.9158 16.0356V42.4696H63.0269V16.0356L47.9688 0.977539Z"
          fill={color}
          fillOpacity="0.12"
          stroke={color}
          strokeOpacity="0.12"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.0093 42.4695H63.0269V23.8457"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.9158 27.8169V42.4698H41.9061"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M32.9158 27.8166V23.7241"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.9158 8.29133V0.977539"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.0093 42.4696V27.4014"
          stroke={color}
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M27.5874 21.364L47.9688 0.977539L68.3552 21.364"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.864 45.8275C80.4047 43.062 76.221 42.1352 71.0345 46.0301C65.3618 50.2897 57.0198 56.5956 57.0198 56.5956L56.0017 56.8792C54.229 54.0023 51.2407 53.6326 50.0757 53.6326H39.3887C32.2218 47.4432 21.3169 47.6914 14.2615 54.4683L1.47754 66.7458L14.3476 84.0223L21.3676 76.5515C22.9124 74.9054 25.0751 73.9684 27.3341 73.9684H55.4041C56.3765 73.9684 57.3186 73.5986 58.0277 72.9301L83.9653 48.5119C84.7504 47.7724 84.7048 46.5062 83.864 45.8326V45.8275Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.4134 54.0376C60.4134 54.0376 65.3618 50.2946 71.0346 46.035C76.2261 42.14 80.4047 43.0669 83.8641 45.8324C84.7049 46.506 84.7505 47.7723 83.9654 48.5117L58.0278 72.9299C57.3187 73.5985 56.3817 73.9682 55.4041 73.9682H27.3342C25.0752 73.9682 22.9176 74.9001 21.3677 76.5513L14.3477 84.0221"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.1291 62.6429H56.2195C56.8678 62.6429 57.3845 62.0959 57.3389 61.4476C56.8628 54.6099 52.588 53.6273 50.0757 53.6273H39.3887C32.2218 47.4379 21.317 47.6861 14.2615 54.463L1.47754 66.7455"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1141_2959">
          <rect
            width="85"
            height={height}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AgencyIcon
