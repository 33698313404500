import { useTheme, type SvgIconProps } from '@mui/material'

type Props = {
  color?: string
  width?: number | string
  height?: number | string
}

import { ReactElement } from 'react'

function ComissionIcon
  ({
    color,
    width = '86',
    height = '87',
    ...props
  }: Readonly<Props>): ReactElement<SvgIconProps> {
  // if color is undefined, get the primary main from useTheme
  const theme = useTheme()
  if (!color) {
    color = theme.palette.secondary.main
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.2008 58.3431C58.2008 58.3431 66.1844 52.3087 71.6154 48.2341C76.5818 44.5066 80.587 45.3931 83.8926 48.0418C84.6989 48.688 84.7417 49.9002 83.9887 50.6051L59.1674 73.9792C58.4892 74.62 57.5921 74.9725 56.6575 74.9725H27.5481C25.3853 74.9725 23.3186 75.8643 21.8394 77.445L14.7156 85.0281"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4999 62.9891C58.0086 56.159 53.0956 55.5074 51.5522 55.5074"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2459 64.1265H57.4318C58.0513 64.1265 58.548 63.6032 58.5052 62.9837C58.0139 56.1536 53.1009 55.5021 51.5576 55.5021H41.4433C36.7279 52.3353 33.7854 51.6838 28.2583 51.6838C21.6525 51.6838 16.3497 54.8826 9.65839 63.2721L1.48254 70.9673"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.4019 24.8173C71.4019 37.4309 61.1754 47.6574 48.5618 47.6574C35.9483 47.6574 25.7218 37.4309 25.7218 24.8173C25.7218 12.2038 35.9483 1.97729 48.5618 1.97729C61.1754 1.97729 71.4019 12.2038 71.4019 24.8173Z"
        fill={color}
        fillOpacity="0.12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4774 18.7936C53.2131 12.7538 42.9867 13.4694 42.9119 19.7602C42.8799 22.5745 46.1801 23.8721 48.941 24.3047C58.9966 25.8907 56.2197 35.3482 48.941 34.9904C43.4192 34.7181 41.8225 30.9853 41.6516 30.0935"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.0531 9.56042V14.6283"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.0531 35.0011V40.0743"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default ComissionIcon
