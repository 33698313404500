import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material'
import type { MouseEventHandler, ReactNode } from 'react'

interface Props {
  Icon: ReactNode
  title: string
  description: string
  linkText?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export function InfoCard({
  title,
  description,
  linkText = '',
  onClick,
  Icon,
}: Readonly<Props>): JSX.Element {
  return (
    <Card
      sx={{ width: '342px', minHeight: '220px', marginBottom: 3, padding: 2 }}
    >
      <CardContent sx={{ padding: 0, paddingBottom: 1 }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            marginBottom: 3,
          }}
        >
          {title}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="caption"
            sx={{
              marginRight: '41px',
              maxWidth: '151px',
            }}
          >
            {description}
          </Typography>
          {Icon}
        </Box>
      </CardContent>
      <CardActions sx={{ padding: 0 }}>
        <Button
          variant="text"
          size="small"
          onClick={onClick}
          sx={{ fontWeight: 500 }}
        >
          {linkText}
        </Button>
      </CardActions>
    </Card>
  )
}


