import { useTheme, type SvgIconProps } from '@mui/material'

type Props = {
  color?: string
  width?: number | string
  height?: number | string
}

import { ReactElement } from 'react'

function AlertIcon
  ({
    color,
    width = '86',
    height = '81',
    ...props
  }: Readonly<Props>): ReactElement<SvgIconProps> {
  // if color is undefined, get the primary main from useTheme
  const theme = useTheme()
  if (!color) {
    color = theme.palette.secondary.main
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1141_2774)">
        <path
          d="M33.0479 1.08789L14.7051 17.7226V3.03265H7.26433L7.25305 62.2153H26.3511V39.1544H39.739V62.2153H58.8427V24.487L33.0479 1.08789Z"
          fill={color}
          fillOpacity="0.12"
          stroke={color}
          strokeOpacity="0.12"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7051 3.16797H7.26428V16.2401"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.5078 29.7632L33.0479 1.22314L1.58789 29.7632"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.2711 39.2955H26.0468V62.3564H7.17981V33.123"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.0953 31.6514L29.7728 78.9738H84.4177L57.0953 31.6514Z"
          fill="white"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.0952 47.6099V64.6561"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.0952 68.8784V71.0825"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1141_2774">
          <rect
            width="85"
            height="80.062"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AlertIcon
