import { useTheme, type SvgIconProps } from '@mui/material'

type Props = {
  color?: string
  width?: number | string
  height?: number | string
}

import { ReactElement } from 'react'

function OwnerIcon
  ({
    color,
    width = '49',
    height = '47',
    ...props
  }: Readonly<Props>): ReactElement<SvgIconProps> {
  // if color is undefined, get the primary main from useTheme
  const theme = useTheme()
  if (!color) {
    color = theme.palette.secondary.main
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.6479 23.693H32.5779V13.8622"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6807 15.9556V23.693H21.4263"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M16.6807 15.9556V13.798"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6807 5.65154V1.78818"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6479 23.693V15.7364"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.8682 12.5495L24.6294 1.78818L35.3907 12.5495"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.3553 25.5726C42.0931 23.5166 44.3015 24.0058 46.1276 25.4656V25.471C46.5714 25.8266 46.5955 26.495 46.1811 26.8853L32.4895 39.7748C32.1152 40.1277 31.6179 40.3229 31.1046 40.3229H16.2875C15.095 40.3229 13.9534 40.8175 13.1379 41.6864L9.43231 45.63M31.4201 31.2994C30.4843 29.7808 28.9069 29.5857 28.292 29.5857H22.6506C18.8675 26.3185 13.1112 26.4495 9.38686 30.0268L2.63867 36.5103"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7486 29.7969C33.7486 29.7969 36.3607 27.8211 39.3552 25.5726C42.0956 23.5166 44.3013 24.0058 46.1274 25.4656C46.5712 25.8212 46.5953 26.4896 46.1809 26.88L32.4893 39.7694C32.115 40.1223 31.6204 40.3175 31.1044 40.3175H16.2873C15.0948 40.3175 13.9559 40.8095 13.1377 41.6811L9.43213 45.6246"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4021 34.3393H31.535C31.8773 34.3393 32.15 34.0506 32.1259 33.7084C31.8746 30.099 29.6181 29.5803 28.292 29.5803H22.6506C18.8675 26.3132 13.1112 26.4442 9.38686 30.0215L2.63867 36.505"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OwnerIcon
