import AgencyIcon from './icons/AgencyIcon'
import Agent2Icon from './icons/Agent2Icon'
import AgentIcon from './icons/AgentIcon'
import AlertIcon from './icons/AlertIcon'
import ComissionIcon from './icons/ComissionIcon'
import OwnerIcon from './icons/OwnerIcon'
import TenantIcon from './icons/TenantIcon'

interface IconComponents {
  Agency: typeof AgencyIcon
  Agent: typeof AgentIcon
  Agent2: typeof Agent2Icon
  Alert: typeof AlertIcon
  Comission: typeof ComissionIcon
  Owner: typeof OwnerIcon
  Tenant: typeof TenantIcon
}

const Icon: IconComponents = {
  Agency: AgencyIcon,
  Agent: AgentIcon,
  Agent2: Agent2Icon,
  Alert: AlertIcon,
  Comission: ComissionIcon,
  Owner: OwnerIcon,
  Tenant: TenantIcon,
}

export default Icon
