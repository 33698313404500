import { useTheme, type SvgIconProps } from '@mui/material'

type Props = {
  color?: string
  width?: number | string
  height?: number | string
}

import { ReactElement } from 'react'

function AgentIcon
  ({
    color,
    width = '52',
    height = '49',
    ...props
  }: Readonly<Props>): ReactElement<SvgIconProps> {
  // if color is undefined, get the primary main from useTheme
  const theme = useTheme()
  if (!color) {
    color = theme.palette.secondary.main
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 14.2638L15.4375 1L23.6321 8.79892"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.27502 15.8091L4.24561 28.2708H23.2389"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.24561 7.85516L4.27502 2.33148H7.60366"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1729 46.3016L12.4991 37.1927C12.3655 35.3719 13.8413 33.6475 15.7797 33.3587L24.7336 32.1288L30.4203 45.9086C30.6102 46.4166 31.3802 46.4166 31.57 45.9086L37.2568 32.1288L46.2107 33.3587C48.149 33.6475 49.6275 35.3719 49.4912 37.1927L48.8174 46.3016"
        stroke="#01BA16"
        strokeOpacity="0.12"
        strokeWidth="3.86"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9718 21.5574C21.8569 20.6885 21.7954 19.7875 21.7954 18.8624H21.79C21.79 13.5446 25.9127 9.23474 30.9979 9.23474C36.0832 9.23474 40.2059 13.5446 40.2059 18.8624C40.2059 19.7875 40.1444 20.6885 40.0294 21.5574H40.0241C39.877 22.814 38.9279 23.6963 37.7328 23.6963C37.6098 23.6963 37.4895 23.6802 37.3718 23.6589C36.3666 27.7468 33.8935 30.6397 30.9979 30.6397C28.1024 30.6397 25.6293 27.7441 24.6241 23.6589C24.5064 23.6776 24.3861 23.6963 24.2631 23.6963C23.068 23.6963 22.1189 22.814 21.9718 21.5574Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.1841 19.6297C39.211 19.8703 38.1709 19.9987 37.0935 19.9987C32.7462 19.9987 28.4764 17.8865 27.022 14.9161"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.998 34.0084V37.8798"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.4814 41.4891H42.401"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1729 46.3016L12.4991 37.1927C12.3655 35.3719 13.8413 33.6475 15.7797 33.3587L24.7336 32.1288L30.4203 45.9086C30.6102 46.4166 31.3802 46.4166 31.57 45.9086L37.2568 32.1288L46.2107 33.3587C48.149 33.6475 49.6275 35.3719 49.4912 37.1927L48.8174 46.3016"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AgentIcon
