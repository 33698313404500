import { useTheme, type SvgIconProps } from '@mui/material'

type Props = {
  color?: string
  width?: number | string
  height?: number | string
}

import { ReactElement } from 'react'

function TenantIcon
  ({
    color,
    width = '49',
    height = '47',
    ...props
  }: Readonly<Props>): ReactElement<SvgIconProps> {
  // if color is undefined, get the primary main from useTheme
  const theme = useTheme()
  if (!color) {
    color = theme.palette.secondary.main
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9005 4.56299H6.50244V14.5329"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50244 45.4719V22.3719"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.5078 27.7993V22.3719"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50244 45.4719H20.9881"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.1947 22.7943L24.5145 1.63539L1.83691 22.7943"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.8868 45.4719V32.8097C44.8868 31.5237 44.0098 30.3312 42.7425 30.1227C41.1277 29.858 39.7294 31.0986 39.7294 32.6653V31.1306C39.7294 29.8446 38.8551 28.6495 37.5851 28.4437C35.9703 28.179 34.572 29.4195 34.5693 30.9863V29.3099C34.5693 28.0239 33.695 26.8315 32.4251 26.6229C30.8102 26.3583 29.4119 27.5988 29.4092 29.1655V18.1797C29.4092 16.8937 28.535 15.7012 27.265 15.4927C25.6502 15.228 24.2518 16.4686 24.2518 18.0353V37.0901C24.2492 37.6302 23.554 37.8468 23.2466 37.4056L20.6879 33.5021C19.188 31.4167 17.4448 30.7002 15.3969 31.0932C14.5573 31.2536 14.1563 32.2375 14.5627 32.9888L21.2895 45.4665"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TenantIcon
